.faqs {
  padding-top: 60px;
}

.faqs_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 40px;
}

.faq {
  padding: 2rem 1.5rem;
  display: flex;
  align-items: start;
  gap: 1.4rem;
  height: fit-content;
  /* background: var(--magenda); */
  cursor: pointer;
}

.faq h4 {
  font-size: 1rem;
  line-height: 2.2;
  color: #fff;
  font-weight: 600;
  text-transform:capitalize;
}

.faq_icon {
  display: flex;
  font-size: 1.2rem;
}

.faq_icon i {
  align-self: flex-start;
  color: var(--white);
}

.answer {
  margin-top: 0.8rem;
  display: none;
}

.faq.open .answer {
  font-size: 0.75rem;
  color: var(--white);
  display: block;
}

.answer .upheading {
  color: var(--white);
  font-weight: 500;
  font-size: 0.75rem;
}
.answer h3 {
  color: var(--white);
  font-weight: 500;
  font-size: 0.8rem;
}

.answer ol li {
  list-style-type: upper-roman;
  margin: 5px 0;
  font-size: 0.75rem;
  color: var(--white);
}
.answer ol li strong {
  font-size: 0.8rem;
  color: var(--white);
  margin-right: 5px;
  font-weight: 500;
}

.answer a {
  color: var(--white);
  margin: 0 5px;
  font-size: 0.8rem;
  text-decoration: underline;
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
  .faqs_container {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 576px) {
}
