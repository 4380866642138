.services{
    margin: 100px 0;
}

.services h1{
    margin-bottom: 60px;
}
.services h1::after{
    content: "Take a look";
}

.services .container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.service_box{
    background: url("../../resource/Service/computer.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
    min-height: 250px;
}

.service_box:nth-child(2){
    background: url("../../resource/Service/makeup.jpg");
    background-size: cover;
}

.service_box:nth-child(3){
    background: url("../../resource/Service/1.png");
    background-size: cover;
}
.service_box:nth-child(4){
    background: url("../../resource/Service/english.jpg");
    background-size: cover;
}

.service_content{
    background: #ed1967bb;
    height: 80%;
    position: absolute;
    width: 100%;
    bottom: -155px;
    padding: 10px 20px;
    transition: all 0.5s ease;
}

.service_box:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.598);
}

.service_box:hover .service_content{
    bottom: 0;
}

.service_content h5{
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 10px;
}
.service_content p{
    color: var(--white);
    font-size: 0.75rem;
    margin-bottom: 15px;
    text-align: justify;
}
.service_content span{
    color: var(--white);
    font-size: 12px;
    padding: 5px 12px;
    border: 1px solid var(--white);
    border-radius: 25px;
    font-weight: 500;
    transition: all 0.5s ease;
}

.service_content span:hover{
    color: var(--indigo);
    background: #fff;
    box-shadow: 0 0 10px #091359;
}


@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
    .services .container{
        grid-template-columns: 1fr 1fr;
      }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .services .container{
        grid-template-columns: 1fr;
      }
      .service_box{
    min-height: 350px;
}
.service_content{
    height: 60%;
    bottom: -160px;
}
}
@media (max-width: 576px) {
    .service_box{
    min-height: 300px;
}
.service_content{
    height: 70%;
    bottom: -160px;
}
}
