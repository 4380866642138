.refund{
    padding-top: 60px;
}

.refund > ul > li{
    list-style-type: circle;
    padding: 10px 0;
}
.refund > ul > li > h3{
    font-size: 1.5rem;
    color: var(--magenda);
    margin-bottom: 10px;
}

.refund > ul > li > ul{
    padding-left: 20px;
}
.refund > ul > li > ul > li{
    list-style-type: square;
}
.refund > ul > li > ul > li > h5{
   font-size: 1.1rem;
   color: var(--indigo);
}