.testimonial{
    margin: 100px 0 0;
}

.sec_heading::after{
    content: "Testimonial";
}

.sec_heading{
    margin-bottom: 30px;
}

.testimonial .container{
   display: flex;
   flex-wrap: wrap;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 30px;
}

.testimonial_video{
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    justify-content: center;
    overflow: hidden;
}
.testimonial_video iframe {
    /* border: 1px solid red; */
    /* height: 215px; */
    width: 100%;
    height: 220px;
}
.fb-video span{
    width: 100%;
    height: 100%;
}
.fb-video span iframe{
    height: 100%;
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
    .testimonial .container{
        grid-template-columns: 1fr 1fr;
     }
     .testimonial_video iframe {
        width: 100%;
        height: 200px;
    }
     .fb-video span iframe{
        height: 100%;
    }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .testimonial .container{
        grid-template-columns: 1fr;
     }
     .testimonial_video iframe {
        width: 100%;
        height: 350px;
    }
    .fb-video span{
        width: 100%;
        height: 100%;
    }
    .fb-video span iframe{
        width: 100% !important;
    }

}
@media (max-width: 576px) {
    .testimonial_video iframe {
        width: 100%;
        height: 250px;
    }
    .fb-video span{
        width: 100%;
        height: 100%;
    }
    .fb-video span iframe{
        height: 100%;
    }
}
