.contact{
    padding-top: 60px;
}

.contact .container{
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 10px;
}

.contact-left h1{
    font-size: 3rem;
    font-weight: 500;
    color: var(--magenda);
}

.contact-left p{
    color: var(--indigo);
    font-size: 1.2rem;
}

.contact-left p strong{
    font-weight: 600;
    color: var(--magenda);
}

input, textarea{
    border: none;
    outline: none;
    padding: 10px 5px ;
}

#number input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.con-name{
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.con-name .field{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 4px;
    padding-left: 15px;
}

.name{
    margin-right: 40px;
}

.con-name .field input{
    width: 100%;
    font-size: 1.1rem;
    text-transform: capitalize;
}

.con-email{
    width: 100%; 
    border: 1px solid rgba(0, 0, 0, 0.33);
    padding: 2px 0;
    margin-bottom: 20px;
    border-radius: 4px;
    padding-left: 15px;
}

.con-email input{
    width: 100%;
    font-size: 18px;
    text-transform: lowercase;
}

.con-textarea{
    border: 1px solid rgba(0, 0, 0, 0.33);
    width: 100%;
    height: 100%;
    padding-left: 15px;
    border-radius: 5px;
}

.con-textarea textarea{
    width: 100%;
    min-height: 100%;
    resize: none;
    font-size: 18px;
}

.contact button{
    padding: 15px 30px;
    border-radius: 10px;
    margin: 20px;
    background: var(--indigo);
    color: #fff;
    transition: all 0.3s ease;
    margin-left: 0;
    border: 0;
    outline: 0;
}

.contact button:hover{
    background-color: var(--magenda);
}

.contact-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-container{
    width: 80%;
}

.contact .address{
    display: flex;
    margin: 30px 0;
    align-items: center;
}

.contact .address i{
    margin-right: 30px;
    font-size: 1.5rem;
    color: var(--magenda);
}

.contact .address p{
    color: var(--indigo);
    font-weight: 500;
    margin: 0;
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
    .contact .container{
        grid-template-columns: 1fr 1fr;
      }
      .contact .right-container{
        width: 90%;
      }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .contact .container{
        grid-template-columns: 1fr;
      }
      .contact-right{
        justify-content: left;
      }
}
@media (max-width: 576px) {
}
