.weprovide .sec_heading {
  margin-bottom: 50px;
}

.weprovide .sec_heading::after {
  content: "Repertoire";
}

.weprovide .container {
  display: grid;
  align-content: center;
  gap: 20px;
  grid-template-columns: 3fr 4fr;
}

.provide_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.provide_right {
  display: flex;
  align-items: center;
}
.provide_left .card_icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.provide_left .card_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 10px;
}

.card_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  background-color: #e4e4e4;
  /* border: 1px solid #00000069; */
  height: 100px;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000077;
}

.card_item i {
  font-size: 1.5rem;
  color: var(--magenda);
  padding-bottom: 10px;
}

.card_item span {
  text-align: center;
}

.imgbox {
  width: 110px;
  height: 110px;
  /* border: 1px solid #000; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  background-position: center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  position: relative;
}
.imgbox:nth-child(1) {
    background-image: url(../../resource/Service/clcstudent.webp);
}
.imgbox:nth-child(2) {
    background-image: url(../../resource/Service/model-4255847.jpg);
}
.imgbox:nth-child(3) {
    background-image: url(../../resource/Service/dress.jpg);
}
.imgbox:nth-child(4) {
    background-image: url(../../resource/Service//8-SM954116.jpg);
}

.imgbox span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    font-size: 0.75rem;
    background-color: var(--magenda);
    text-align: center;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlight {
  color: var(--magenda);
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
  .weprovide .container {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .imgbox {
    width: 150px;
    height: 150px;
  }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
  .weprovide {
    padding-bottom: 20px;
  }
}
@media (max-width: 576px) {
}
