.roadmap{
    width: 100%;
    /* border: 1px solid red; */
    margin-top: 60px;
  }
  
  .roadmap h1::after{
    content: "From the past";
  }
  
  .roadmap .container{
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  
  .line{
    height: 3px;
    background-color: var(--indigo);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .line_box{
    height: 60px;
    position: relative;
    width: 60px;
    background: var(--magenda);
    box-shadow: 0 0 0px 5px var(--white), 0 0 0px 7px var(--magenda);
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .line_box i{
    color: var(--white);
  }
  
  .line_box:nth-child(odd) .innerline{
    width: 2px;
    height: 120px;
    background-color: var(--magenda);
    position: absolute;
    top: 115%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .line_box:nth-child(odd) .innerline:after{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: var(--magenda);
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
  
  .line_box:nth-child(even) .innerline{
    width: 2px;
    height: 120px;
    background-color: var(--magenda);
    position: absolute;
    top: -210%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .line_box:nth-child(even) .innerline:after{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: var(--magenda);
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
  
  .innerline{
    position: relative;
  }
  
  .line_box:nth-child(odd) .innerline span{
    position: absolute;
    bottom: -25%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600 !important;
  }
  
  .line_box:nth-child(even) .innerline span{
    position: absolute;
    top: -25%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600 !important;
  }
  
  .line_box:nth-child(odd) .innerline .roadmap_content{
    /* border: 1px solid red; */
    width: 200px;
    position: absolute;
    bottom: -70%;
    left: -50%;
    transform: translateX(-50%);
  }
  .line_box:nth-child(even) .innerline .roadmap_content{
    /* border: 1px solid red; */
    width: 150px;
    position: absolute;
    top: -70%;
    left: -50%;
    transform: translateX(-50%);
  }
  
  .roadmap_content ul li{
    list-style-type: none;
    font-size: 1rem;
    color: rgb(109, 109, 109);
    text-align: center;
  }
  
  .line_box i{
    font-size: 1.5rem;
  }
  
  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 992px) {
  }
  @media (min-width: 769px) and (max-width: 878px) {
  }
  @media (max-width: 768px) {
    .line_box:nth-child(odd) .innerline .roadmap_content{
      width: 150px;
    }
    .line_box:nth-child(even) .innerline .roadmap_content{
      width: 150px;
    }
    .roadmap_content ul li{
      font-size: 0.9rem;
    }
    
    .line_box i{
      font-size: 1rem;
    }
    .line_box:nth-child(1) .innerline .roadmap_content{
      width: 200px;
    }
    .line_box{
      height: 50px;
      width: 50px;
    }
  }
  @media (max-width: 576px) {
    .line_box{
      height: 45px;
      width: 45px;
    }
    .line_box:nth-child(2) .innerline{
      width: 2px;
      height: 50px;
      background-color: var(--magenda);
      position: absolute;
      top: -125%;
      left: 50%;
      transform: translateX(-50%);
    }
    .line_box:nth-child(4) .innerline{
      top: -280%;
      left: 50%;
      transform: translateX(-50%);
    }
    .line_box:nth-child(2) .innerline span{
      position: absolute;
      top: -50%;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .line_box:nth-child(2) .innerline .roadmap_content{
      /* border: 1px solid red; */
      width: 200px;
      position: absolute;
      top: -130%;
      left: -50%;
      transform: translateX(-50%);
    }
    .line_box:nth-child(4) .innerline .roadmap_content{
      width: 200px;
      position: absolute;
      top: -60%;
      left: -50%;
      transform: translateX(-50%);
    }
  
    .line_box:nth-child(3) .innerline{
      width: 2px;
      height: 50px;
      background-color: var(--magenda);
      position: absolute;
      top: 115%;
      left: 50%;
      transform: translateX(-50%);
    }
    .line_box:nth-child(3) .innerline span{
      position: absolute;
      bottom: -50%;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .line_box:nth-child(3) .innerline .roadmap_content{
      /* border: 1px solid red; */
      width: 200px;
      position: absolute;
      bottom: -170%;
      left: -50%;
      transform: translateX(-50%);
    }
    .line_box:nth-child(5) .innerline .roadmap_content{
      /* border: 1px solid red; */
      width: 200px;
      position: absolute;
      bottom: -60%;
      left: -50%;
      transform: translateX(-50%);
    }
    .line_box .innerline .roadmap_content{
      width: 100px !important;
    }
    .roadmap_content ul li{
      list-style-type: none;
      font-size: 0.75rem;
    }
    .line_box i{
      font-size: 1rem;
    }
  }