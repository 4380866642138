.branch_container {
  padding-top: 60px;
  overflow: scroll;
}


.branch_container .container table th {
  font-size: 0.9rem;
  padding: 5px 0;
  border: 1px solid white;
  background-color: var(--magenda);
  color: var(--white);
  text-align: center;
}

tr > td {
  padding: 5px 0;
  border: 1px solid white;
  text-align: center;
  color: var(--white);
  background-color: var(--magenda);
}

tbody tr:nth-child(odd) td{
    border: 1px solid var(--magenda);
    color: var(--magenda);
    background-color: var(--white) !important;
  }

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  /* tr > td:nth-child(2) {
    max-width: 150px;
    text-align: center;
  }
  tr > td:nth-child(1) {
    max-width: 100px;
  } */
}
