.overviewcontent {
  padding-top: 60px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
}

.overviewcontent .paragraph {
  width: 100%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overviewcontent .paragraph h1 {
  text-align: left;
  padding: 0;
  margin-bottom: 10px;
}
.overviewcontent .paragraph h1:after, .overviewcontent .paragraph h1::before {
  display: none;
}

.overviewcontent .overview_img {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  border-radius: 5px;
}
.overviewcontent .overview_img img {
  transition: all 0.3s linear;
  width: 100%;
  transform: scale(1.1);
}
.overviewcontent .overview_img:hover img {
  transform: scale(1.2);
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
  .overviewcontent {
    grid-template-columns: 1.5fr 1fr;
    gap: 30px;
  }
  .overviewcontent .overview_img {
    width: 100%;
  }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .overviewcontent {
        grid-template-columns: 1fr;
        gap: 30px;
      }
}
@media (max-width: 576px) {
}
