.hero{
    width: 100%;
    height: 50vh;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hero .container h1{
    color: var(--white);
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}

.hero .container p a{
    color: var(--magenda);
    font-weight: 400;
    position: relative;
}

.hero .container p a::after{
    position: absolute;
    content: "";
    bottom: -2px;
    height: 3px;
    border-radius: 5px;
    width: 0%;
    background-color: var(--magenda);
    left: 0;
    transition: all 1s ease;
}

.hero .container p a:hover:after{
    width: 80%;
}

.hero .container p{
    color: var(--white);
}

@media (max-width: 576px) {
    .hero .container{
        flex-direction: column;
        gap: 20px;
      }
}