.quarterlyreports{
 padding-top: 60px;
}

.year_container{
    border: 1px solid var(--indigo);
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.year_container_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.year_container_head *{
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--magenda);
}
.year_container_head i{
    cursor: pointer;;
}

.reportsec_container{
    /* border: 1px solid red; */
    display: none;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}
.show_reportsec_container{
    display: grid;
}

.reportsec_container .reports{
    min-width: 250px;
    height: 220px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.247);
    object-fit: cover;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.reportsec_container .reports img{
   width: 100%;
   object-position:  46% center;
   object-fit: cover;
   height: 100%;
}
.reportsec_container .reports span{
   position: absolute;
   bottom: 0;
   left: 0;
   font-size: 1rem;
   padding: 10px ;
   background-color: var(--magenda);
   width: 100%;
   text-align: center;
   color: var(--white);
   font-weight: 500;
}



@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
    .reportsec_container .reports{
        min-width: 200px;
        height: 180px;
    }
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
    .reportsec_container{
        grid-template-columns: 1fr 1fr;
    }
    .reportsec_container .reports{
        min-width: 270px;
        height: 250px;
    }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .reportsec_container .reports{
        min-width: 250px;
        height: 220px;
    }
}
@media (max-width: 660px) {
    .reportsec_container{
        grid-template-columns: 1fr;
    }
    .reportsec_container .reports{
        height: 250px;
    }
}
@media (max-width: 576px) {
}
