.cancle{
    padding-top: 60px;
}
.cancle *{
    text-align: justify;
}

.cancle h3{
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: var(--magenda);
    text-align: left;
}

.cancle > ul > li{
    margin-bottom: 40px;
}

.cancle > ul > li > ul{
    padding-left: 20px;
}
.cancle > ul > li > ul > li{
    list-style-type: square;
}
.cancle > ul > li > ul > li > h5{
    font-size: 1rem;
    color: var(--indigo);
}
.cancle > ul > li > ul > li > ul{
   padding-left: 20px;
}
.cancle > ul > li > ul > li > ul > li{
   list-style-type: circle;
   margin: 10px 0;
}