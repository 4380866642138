footer {
  background-color: rgb(241, 241, 241);
  padding: 50px 0px;
  margin-top: 80px;
  border-bottom: 1px solid var(--black);
}

footer .container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  gap: 50px;
}

.company_info{
  min-width: none;
}

.company_info h1 {
  font-size: 1.25rem;
  color: var(--magenda);
}
.company_info > a {
  font-size: 0.9rem;
  color: var(--indigo);
  font-weight: 500;
  margin: 20px 0 10px;
}

.company_info .quicklink {
  font-size: 0.75rem;
}

.quicklink p {
  text-align: justify;
  color: var(--black);
}

.address h1 {
  margin-bottom: 20px;
}

.address ul li {
  margin: 0 0 10px 0;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--indigo);
}

.address ul li a i {
  font-size: 20px;
  /* color: var(--magenda); */
  margin-right: 30px;
  font-weight: 900;
  transition: all 0.5s ease;
}
.address ul li a i:hover {
  opacity: 2;
  color: var(--indigo);
}

.address ul li span{
  margin: 0px;
  color: var(--indigo);
}

.copyright_policy .container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright p {
  text-align: center;
  color: var(--magenda);
  font-weight: 500;
}

.copyright p a {
  color: var(--indigo);
  font-weight: 600;
}

/* .policy a{
  margin: 0 10px;
  font-size: 0.8rem;
} */

.QR {
  width: 80px;
}

.whatsapp {
  font-size: 1rem;
  color: var(--magenda);
  font-weight: 600;
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}


@media (max-width: 1025px) {
  footer .container {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 50px;
  }
}

@media (min-width: 769px) and (max-width: 878px) {

}
@media (max-width: 768px) {
  .copyright_policy .container {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .copyright_policy .container .policy {
    order: 1;
    margin: 0 5px;
  }
  .copyright_policy .container .policy a{
    font-size: 0.75rem;
  }
  .copyright_policy .container .copyright {
    order: 2;
  }
}

@media (max-width: 576px) {
  footer .container {
    grid-template-columns: 1fr;
  }
}

.social-link{
  cursor: pointer;
}

