.computer_content, .makeup_content, .dress_content, .spoken_content {
  padding-top: 60px;
}

.computer_content .conatiner, .makeup_content .conatiner, .dress_content .conatiner, .spoken_content .conatiner{
  height: auto;
}

.domain_intro{
  margin-bottom: 30px;
}

.course_icon{
  cursor: pointer;
}
.course_icon i{
    font-size: 1.5rem;
    color: var(--indigo);
}

.course {
  border: 1px solid var(--indigo);
  padding: 10px 30px;
  border-radius: 10px;
  margin: 20px 0;
}

.course_contant_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.course_contant_header h4 {
  color: var(--magenda);
  font-size: 1.25rem;
}

.domain_intro p{
    text-align: justify;
    font-size: 1rem;
}

.course_intro {
    color: #252525;
    text-align: justify;
}

.critaria, .duration h3, .course-highlight h3, .course-fees h3{
    color: var(--magenda);
    font-weight: 600;
    text-transform: capitalize;
}

.critaria_content, .duration p, .course-highlight p, .course-fees p{
    color: #252525;
    font-size: 0.9rem;
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
    .course_contant_header h4 {
        color: var(--magenda);
        font-size: 1rem;
      }
      .course {
        padding: 10px 20px;
      }
}
