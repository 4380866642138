.container {
  padding: 10px 0;
}

.container .intro {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: fit-content;
  padding: 5px 0;
  /* border: 2px solid black; */
  /* padding: 2rem 0; */
}

.headerText {
  font-size: 2rem;
  color: var(--magenda);
  font-stretch: extra-expanded;
  padding: 2rem 0;
}

.slide {
  width: 50%;
  max-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.slide > Swiper {
  width: 100%;
  height: fit-content;
  min-height: fit-content;
}

.mySwiper {
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide img {
  object-position: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.intro_text {
  width: 50%;
  height: auto;
  /* border: 2px solid black; */
  font-weight: lighter;
  font-stretch: extra-expanded;
  text-justify: distribute-all-lines;
  padding: 0 0.5rem;
  line-height: 2rem;
}

.employee-speak {
  padding: 2rem 1rem;
  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #f1dfe6 49%,
    #ffffff 100%
  );

  border-radius: 5px;
}

.employee-speak-title {
  font-size: 2rem;
  color: var(--magenda);
  font-stretch: extra-expanded;
  padding: 2rem 0;
}

.employee-corner {
  padding: 2rem 1rem;
  width: 100%;
  height: auto;
}

.employee-moments {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  gap: .5rem;
}

.employee-moments-item {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  border-radius: 10px;

}

.employee-moments-item img {
  width: 100%;
  display: block;
  object-fit: contain;
  border-radius: 10px;
}

.employee-moments-item > .image-caption{
  position: absolute;
  text-align: center;
  color: black;
  background-color: rgba(195, 228, 236, 0.849);
  padding: .5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-stretch: extra-expanded;
  top: 83%;
  font-weight: lighter;
  font-size: .9rem;
}


@media (max-width: 768px) {
  .intro {
    flex-direction: column;
  }

  .slide {
    width: 100%;
  }
  .intro_text {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .intro_text {
    line-height: 1.6rem;
  }
}

@media (max-width: 950px) {
  .intro_text {
    font-size: 12px;
  }
}
@media (max-width : 768px){
  .employee-moments{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
