.target-people{
    padding-top: 60px;
}

.target-people .container {
 display: grid;
 grid-template-columns: 2fr 1fr;
 gap: 30px;
}
.targetplleft {
  text-align: justify;
  font-size: 1rem;
}
.targetplright {
  display: flex;
  justify-content: center;
  align-items: center;
}

.targetplright img{
  width: 100%;
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
  .target-people .container {
    grid-template-columns: 1.5fr 1fr;
   }
}
@media (max-width: 992px) {
  .target-people .container {
    grid-template-columns: 1fr;
   }
   .targetplright{
    order: 1;
  }
  .targetplleft {
    order: 2;
  }
   .targetplright img {
    width: 70%;
  }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
  .targetplright img {
   width: 100%;
 }
}
@media (max-width: 576px) {
}
