.annualyreports{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;   
}

.annualyreports .reports{
    min-width: 250px;
    height: 220px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.247);
    object-fit: cover;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.annualyreports a img{
    width: 100%;
    object-position:  46% center;
    object-fit: cover;
    height: 100%;
}

.reports span{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    padding: 10px ;
    background-color: var(--magenda);
    width: 100%;
    text-align: center;
    color: var(--white);
    font-weight: 500;
 }

 
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
    .annualyreports{
        grid-template-columns: 1fr 1fr;
    }
    .annualyreports .reports{
        min-width: 270px;
        height: 250px;
    }
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .annualyreports .reports{
        min-width: 250px;
        height: 220px;
    }
}
@media (max-width: 660px) {
    .annualyreports{
        grid-template-columns: 1fr;
    }
    .annualyreports .reports{
        height: 250px;
    }
}
@media (max-width: 576px) {
}
