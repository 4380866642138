.privacy_intro_para {
  padding: 60px 0 30px;
}

.privacy * {
  text-align: justify;
}

.privacy_intro_para > h3 {
  margin: 30px 0 20px;
  font-size: 1.25rem;
  color: var(--magenda);
}

.privacy_intro_para ul li {
  list-style-type: circle;
}

.privacy_intro_para ul {
  padding-left: 30px;
}

.privacy_intro_para ul li h3 {
  color: var(--magenda);
}
.privacy_intro_para ul li p {
  font-weight: 500;
}
.privacy_intro_para ul li p span {
  font-weight: 600;
  margin-left: 10px;
  color: var(--indigo);
}

.privacy_overview {
  padding: 30px 0 30px;
}
.privacy_overview h1 {
  font-size: 2rem;
  color: var(--magenda);
  margin-bottom: 10px;
}

.privacy_overview ul {
  padding-left: 30px;
}

.privacy_overview ul > h3 {
  font-size: 1rem;
  color: var(--magenda);
  margin-bottom: 10px;
}

.privacy_overview ul li {
  list-style-type: circle;
  margin: 10px 0;
}
.privacy_overview ul li h3 {
  display: inline;
  font-weight: 600;
  color: var(--indigo);
}

.table_of_content h2 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: var(--magenda);
}

.table_of_content ul {
  padding-left: 30px;
}
.table_of_content ul li {
  list-style-type: square;
  padding-left: 5px;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 5px 0;
}
.table_of_content ul li a {
  cursor: pointer;
}

.about_the_website,
.scope_policy,
.personal_information,
.other_information,
.choices_access,
.children,
.advertising_marketing,
.third_party_services,
.security,
.border_transfers,
.change_policy,
.report_abuse {
  padding: 80px 0 30px;
}

.privacy_para_heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--magenda);
  text-transform: uppercase;
}

.about_the_website ul {
  padding-left: 30px;
}
.about_the_website ul li {
  list-style-type: circle;
  margin: 10px 0;
}
.about_the_website ul li ul li {
  list-style-type: square;
}

.scope_policy ul li {
  padding: 10px 0 10px 20px;
}

.personal_information > ul {
  padding-left: 20px;
}
.personal_information > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.personal_information > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.personal_information > ul > li > ul > li {
  padding: 10px 0;
}
.personal_information > ul > li > ul > li > ul {
  padding: 20px 25px;
}
.personal_information > ul > li > ul > li > ul > li {
  list-style-type: square;
}

.personal_information > ul > li:nth-child(2) ul > li,
.personal_information > ul > li:nth-child(3) ul > li,
.personal_information > ul > li:nth-child(4) ul > li {
  list-style-type: disc;
}

.personal_information h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.other_information > ul {
  padding-left: 20px;
}
.other_information > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.other_information > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.other_information > ul > li > ul > li {
  padding: 10px 0;
}
.other_information > ul > li > ul > li > ul {
  padding: 20px 25px;
}
.other_information > ul > li > ul > li {
  list-style-type: circle;
}

.other_information h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.choices_access > ul {
  padding-left: 20px;
}
.choices_access > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.choices_access > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.choices_access > ul > li > ul > li {
  padding: 10px 0;
}
.choices_access > ul > li > ul > li > ul {
  padding: 20px 25px;
}
.choices_access > ul > li > ul > li {
  list-style-type: circle;
}

.choices_access h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.list_point_bold {
  font-weight: 600;
}

.children h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.children > ul {
  padding-left: 20px;
}
.children > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.children > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.advertising_marketing > ul {
    padding-left: 20px;
  }
  .advertising_marketing > ul > li {
    margin: 20px 0;
    list-style-type: upper-alpha;
  }
  .advertising_marketing > ul > li h3 {
    color: var(--indigo);
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
.third_party_services > ul {
    padding-left: 20px;
  }
  .third_party_services > ul > li {
    margin: 20px 0;
    list-style-type: upper-alpha;
  }
  .third_party_services > ul > li h3 {
    color: var(--indigo);
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
.security > ul {
    padding-left: 20px;
  }
  .security > ul > li {
    margin: 20px 0;
    list-style-type: circle;
  }
.border_transfers > ul {
    padding-left: 20px;
  }
  .border_transfers > ul > li {
    margin: 20px 0;
    list-style-type: circle;
  }

  .privacy a{
    margin: 0 5px;
    font-weight: 700;
    color: var(--indigo);
  }

  .report_abuse span{
    font-weight: 600;
    display: block;
  }