.grievance{
    padding-top: 60px;
}

.grievance_intro{
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--indigo);
}

.grievance ul{
    /* list-style-type: circle; */
    padding-left: 20px;
}

.grievance ul li{
    list-style-type: circle;
    text-align: justify;
    margin: 15px 0;
}

.grievance h3{
    font-size: 1rem;
    margin: 20px 0;
    font-weight: 500;
}
.grievance h2{
    font-size: 1.5rem;
    text-align: justify;
    color: var(--magenda);
}

.form_area{
    width: 100%;
    overflow: scroll;
    padding: 0;
}

.form_area > div{
    display: flex;
    padding: 0;
}
.form_area div p{
    min-width: 150px;
    width: 350px;
    border: 1px solid #ccc; 
    margin: 0;
    padding: 10px;
    font-weight: 500;
    font-size: 0.9rem;
}
.form_area div p:first-child{
    min-width: 100px;
}

.form_body p{
    padding: 30px 0 !important;
    font-weight: 400 !important;
}

.lastpara{
    text-align: justify;
    margin-top: 20px;
}

.lastpara span{
    font-weight: 600;
}